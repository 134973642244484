@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-background {
    background-image: url("./images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}