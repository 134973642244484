.neon-icon {
    width: 50px;
    height: 50px;
}

.neon-icon-link {
    text-decoration: none;
}

.neon-icon:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 1px #FFDD1B) drop-shadow(0 0 2px #FFDD1B) drop-shadow(0 0 3px #FFDD1B) drop-shadow(0 0 4px #FFDD1B) drop-shadow(0 0 5px #FFDD1B) drop-shadow(0 0 6px #FFDD1B) drop-shadow(0 0 7px #FFDD1B) drop-shadow(0 0 8px #FFDD1B);
}